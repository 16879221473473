import { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Box } from "@mui/material";

// Initial App loading fallback
const InitialLoadingFallback = () => (
  <Box
    sx={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bgcolor: "#fafafa",
      zIndex: 9999,
    }}
  ></Box>
);

// Lazy load the main App component
const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Suspense fallback={<InitialLoadingFallback />}>
    <App />
  </Suspense>,
);
